/* eslint-disable no-undef */
/* eslint-disable complexity */
import React, { useState, useEffect, useRef } from 'react';
import {
    getDictionaryByCode,
    queryMemberTypeById,
    uploadAvatar,
    preAddMember,
    renewMember,
    queryMemberRules,
    queryFamilyInfo,
    baiduFaceDetect,
    checkHasUnpaidOrder,
    queryIsQingHuaPersonByCustomerId
} from '../../service';
import {
    Form,
    Input,
    DatePicker,
    Select,
    Radio,
    Upload,
    Modal,
    message,
    Checkbox
} from 'antd';
import { localDS } from '../../storage';
import { history } from '../../routes';
import dayjs from 'dayjs';
import { dealdate, DocToBirth, DocToAdult, DealBirthday } from '../../utils/validator';
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import app, { $action } from '../../model';
import './style.scss';

import Pay from './pay';

const regs = [
    {
        type: '身份证',
        require: { required: true, message: '请输入身份证号!' },
        pattern: {
            pattern: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|30|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/,
            message: '请输入正确的证件号！'
        }
    },
    {
        type: '护照',
        require: { required: true, message: '请输入护照号码!' },
        pattern: { pattern: /^([a-zA-z]|[0-9]){5,17}$/, message: '请输入正确的护照号码!' }
    },
    {
        type: '台胞',
        require: { required: true, message: '请输入台湾居民来往大陆通行证号码!' },
        pattern: {
            pattern: /^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/,
            message: '请输入正确的台湾居民来往大陆通行证号码!'
        }
    },
    {
        type: '港澳',
        require: { required: true, message: '请输入港澳居民来往内地通行证!' },
        pattern: {
            pattern: /^([A-Z]\d{6,10}(\(\w{1}\))?)$/,
            message: '请输入正确的港澳居民来往内地通行证!'
        }
    },
    {
        type: '军官',
        require: { required: true, message: '请输入军官证!' },
        pattern: {
            pattern: /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/,
            message: '请输入正确的军官证!'
        }
    }
];

const AddMemberForm = (props) => {
    // 续费时，携带信息

    const [form] = Form.useForm();
    const { Option } = Select;
    const [submit, setSubmit] = useState(false);
    const [vipMsg, setVipMsg] = useState({});
    const [education, setEducation] = useState([]);
    const [educationId, setEducationId] = useState('');
    const [industry, setIndustry] = useState([]);
    const [industryId, setIndustryId] = useState('');
    const [photo, setPhoto] = useState('');
    const [studentCardPhoto, setStudentCardPhoto] = useState('');
    const [memberType, setMemberType] = useState({});
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewStudentOpen, setPreviewStudentOpen] = useState(false);
    const [previewStudentImage, setPreviewStudentImage] = useState('');
    const [previewStudentTitle, setPreviewStudentTitle] = useState('');
    const [memberMsg, setMemberMsg] = useState({});
    const [messageApi, contextHolder] = message.useMessage();
    // const type = props.type;
    const [photoShow, setPhotoShow] = useState(true);
    const [photoShow2, setPhotoShow2] = useState(true);
    const [cardDirectionary, setCardDirectionary] = useState([]);
    const [cardType, setCardType] = useState([]);
    const [data, setData] = useState({});
    const [open, setOpen] = useState(false);
    const [isschool, setIsschool] = useState();
    const [charter, setCharter] = useState('');
    const [tkopen, setTkopen] = useState(false);
    const [tipOpen, setTipOpen] = useState(false);
    const [takeMembers, setTakeMembers] = useState([]);
    const [isHuman, setIsHuman] = useState(true);
    const cost = new URLSearchParams(window.location.search)?.get('cost');
    const [isConfirm, setIsconfirm] = useState(submit || (cost && cost !== 'judge') ? true : false);
    const cert = localDS.get('cert'); // 实名认证信息
    const isQ = localDS.get('isQ'); // 是否清华人
    const { infor, isrequest, orderInfo, tempInfo } = props;
    // const isPhysical = new URLSearchParams(window.location.search).get('type') === 'physical';
    const id = new URLSearchParams(window.location.search)?.get('id');
    const memberId = new URLSearchParams(window.location.search)?.get('memberId');
    const isQHR = new URLSearchParams(window.location.search).get('isQHR');
    useEffect(() => {
        if (isrequest) {
            // if (
            //     ((isQHR && isQHR === 'false') || !isQHR) &&
            //     (submit || cost) &&
            //     (infor?.isVip === 1
            //         ? tempInfo?.isSchoolFriend.toString() === '1'
            //         : infor?.isSchoolFriend.toString() === '1') &&
            //     !isQ
            // ) {
            //     setOpen(true);
            // }
            queryMemberRules('POI0702').then((res) => {
                // eslint-disable-next-line no-console
                setCharter(res?.data?.content);
            });
        }
    }, [isrequest]);
    const onFinish = function() {
        if (cost && cost !== 'judge') {
            return;
        }
        let formValues = form.getFieldValue();
        if (!photo) {
            messageApi.open({
                type: 'error',
                content: '请正确上传照片！'
            });
            return;
        }
        if (!isHuman) {
            messageApi.open({
                type: 'error',
                content: '请露正脸拍照，以便入馆识别，请重新拍照！'
            });
            return;
        }
        if (!studentCardPhoto && Number(formValues?.isSchoolFriend) === 1 && !isQ && isQHR && (isQHR !== 'true')) {
            messageApi.open({
                type: 'error',
                content: '请正确上传证件照片！'
            });
            return;
        }
        if (!isConfirm) {
            app.dispatch(
                $action('alert').emit({
                    show: true,
                    title: '提示',
                    content: '请认真阅读《会员章程》，勾选确认后再申请办理。'
                })
            );
            handleCheckScroll();
            return;
        }
        if (
            formValues?.cardType === 'RLY0101' &&
            !DocToAdult(formValues?.documentNumber) &&
            ['2', '3', '4'].indexOf(memberType?.id) > -1
        ) {
            messageApi.open({
                type: 'error',
                content: `仅年满18周岁及以上可申请${memberType?.typeName}`
            });
            return;
        }
        const orderParam = localDS.get('orderParam');
        let data = {
            typeId: id,
            memberName: formValues?.membername,
            // birthday: `${formValues?.birthday?.$y}/${Number(formValues?.birthday?.$M) + 1}/${
            //     formValues?.birthday?.$D
            // }`,
            birthday: formValues?.birthday && DealBirthday(formValues?.birthday),
            gender: formValues?.gender,
            education: educationId || tempInfo?.education || infor?.education || orderParam?.education,
            email: formValues?.email,
            documentType: formValues?.cardType,
            documentNumber: formValues?.documentNumber,
            phone: formValues?.phone,
            // industry: formValues?.industry,
            industry: industryId || tempInfo?.industry || infor?.industry || orderParam?.industry,
            isSchoolFriend: formValues?.isSchoolFriend,
            cost: memberType?.memberCost,
            photo: photo || tempInfo?.photo || infor?.photo,
            studentCardPhoto:
                studentCardPhoto || tempInfo?.studentCardPhoto || infor?.studentCardPhoto,
            // handledBy: memberInfo?.memberName,
            // handledByPhone: memberInfo?.phone,
            handledBy: formValues?.membername,
            handledByPhone: formValues?.phone
        };

        // 判断有无待支付订单
        const requestId = localDS.get('memberId') || memberId;
        if(requestId && (!cost || (cost === 'judge'))) {
            checkHasUnpaidOrder(requestId).then(res => {
                if(res?.data) {
                    app.dispatch(
                        $action('confirm').emit({
                            open: true,
                            content: '您有一笔待支付的会员申请订单，请勿重复提交，并于15分钟内完成支付。若想取消该订单，请点击“申请记录”取消订单后再进行其他操作。',
                            okText: '申请记录',
                            cancelText: '关闭',
                            okCallback: () => {
                                history.push('/applyrecords');
                            }
                        })
                    );
                } else {
                    // 提交申请
                    setData(data);
                handleSubmit(data);
                setMemberMsg(data);
                }
            })
        } else {
            // 提交申请
            setData(data);
            handleSubmit(data);
            setMemberMsg(data);
        }
    };
    const handleSubmit = (val) => {
        const param = val || data;
        if(param?.isSchoolFriend?.toString() !== '1' || isQ || (param?.isSchoolFriend?.toString() === '1' && isQHR && isQHR?.length > 0)) {
        if (param) {
            if (infor?.isVip === 1 || infor?.state === 2) {
                let newdata = param;
                newdata.id = infor?.id;
                if(memberId && memberId !== 'judge' && !newdata.id) {
                    newdata.id = memberId;
                }
                renewMember(newdata).then((res) => {
                    if (res?.code !== 200) {
                        // if (res?.code === 400) {
                        //     setTipOpen(true);
                        // } else {
                        //     messageApi.open({
                        //         type: 'error',
                        //         content: res?.msg
                        //     });
                        // }
                        messageApi.open({
                            type: 'error',
                            content: res?.msg
                        });
                        return;
                    } else {
                        localDS.remove('orderParam');
                        const addStr = isQHR?.length > 0 ? `&isQHR=${isQHR}` : '';
                        history.push(`/addMembers?orderId=${res?.data?.id}${addStr}`);
                    }
                });
            } else {
                let newdata = param;
                if (infor?.id) {
                    newdata.id = infor?.id;
                } else if (memberId && memberId !== 'judge') {
                    newdata.id = memberId;
                }
                preAddMember(newdata).then((res) => {
                    if (res?.code !== 200) {
                        // if (res?.code === 400) {
                        //     setTipOpen(true);
                        // } else {
                        //     messageApi.open({
                        //         type: 'error',
                        //         content: res?.msg
                        //     });
                        // }
                        messageApi.open({
                            type: 'error',
                            content: res?.msg
                        });
                        return;
                    } else {
                        localDS.remove('orderParam');
                        const addStr = isQHR?.length > 0 ? `&isQHR=${isQHR}` : '';
                        history.push(`/addMembers?orderId=${res?.data?.id}${addStr}`);
                    }
                });
            }
        }
        } else {
            localDS.set('orderParam', param);
            console.log('跳转清华人')
            console.log(`id=${id}&memberId=${(infor?.id) ? (infor?.id) : 'judge'}&cost=judge&orderId=judge&openType=1`)
            // 不提交订单去判断是否清华人
            wx.miniProgram.navigateTo({
                url: `../main/vipQinghuaren?isWebviewBack=true&id=${id}&memberId=${(infor?.id) ? (infor?.id) : 'judge'}&cost=judge&orderId=judge&openType=1`
            });
        }
    };
    // 判断是否清华人之后的处理
    useEffect(() => {
        if(cost === 'judge') {
            const data = localDS.get('orderParam');
            if(isQHR === 'true') {
                data && handleSubmit(data)
            } else if(isQHR === 'false') {
                // 再次查询确认是否清华人
                queryIsQingHuaPersonByCustomerId(cert?.customerCertId).then((rs) => {
                    if(rs.data) {
                        data && handleSubmit(data);
                    } else {
                        app.dispatch(
                            $action('alert').emit({
                                show: true,
                                title: '提示',
                                content: '您未通过“清华人小程序”的校友身份验证，请上传校友身份证件照片，继续填写申请信息。',
                                btnText: '关闭',
                            })
                        );
                    }
                })
            }
        }
    }, [])
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    // 人脸识别验证
    const Verify = (val) => {
        let data = {
            image: val.replace(/^data:image\/\w+;base64,/, ''),
            image_type: 'BASE64',
            // 'image': val,
            // 'image_type': 'URL',
            max_face_num: 2,
            face_field: 'quality'
        };
        baiduFaceDetect(data).then((res) => {
            if (res?.data?.error_msg === 'SUCCESS') {
                let result = res?.data?.result;
                if (result.face_num > 1) {
                    messageApi.open({
                        type: 'error',
                        content: '多人一起拍照将导致无法刷脸入馆，请重新拍照'
                    });
                } else if (result.face_list[0].quality.blur > 0.5) {
                    messageApi.open({
                        type: 'error',
                        content: '照片太模糊，请重新拍照'
                    });
                } else if (result.face_list[0].quality.illumination < 60) {
                    messageApi.open({
                        type: 'error',
                        content: '脸部太暗，请重新拍照'
                    });
                } else if (result.face_list[0].quality.completeness === 0) {
                    messageApi.open({
                        type: 'error',
                        content: '脸部拍摄不完整，请重新拍照'
                    });
                } else if (
                    result.face_list[0].quality.occlusion.left_eye > 0.6 ||
                    result.face_list[0].quality.occlusion.right_eye > 0.6
                ) {
                    messageApi.open({
                        type: 'error',
                        content: '眼睛被遮挡，请重新拍照'
                    });
                } else if (result.face_list[0].quality.occlusion.nose > 0.7) {
                    messageApi.open({
                        type: 'error',
                        content: '鼻子被遮挡，请重新拍照'
                    });
                } else if (result.face_list[0].quality.occlusion.mouth > 0.7) {
                    messageApi.open({
                        type: 'error',
                        content: '嘴巴被遮挡，请重新拍照'
                    });
                } else if (result.face_list[0].quality.occlusion.chin_contour > 0.6) {
                    messageApi.open({
                        type: 'error',
                        content: '下巴被遮挡，请重新拍照'
                    });
                } else if (
                    result.face_list[0].quality.occlusion.right_cheek > 0.5 ||
                    result.face_list[0].quality.occlusion.left_cheek > 0.5
                ) {
                    messageApi.open({
                        type: 'error',
                        content: '脸颊被遮挡，请重新拍照'
                    });
                } else {
                    setIsHuman(true);
                }
            } else {
                if(res.code === 222202) {
                    messageApi.open({
                        type: 'error',
                        content: `请露正脸拍照，以便入馆识别，请重新拍照`
                    });
                } else if(res.code === 222304) {
                    messageApi.open({
                        type: 'error',
                        content: `上传图片过大,请重新上传`
                    });
                } else {
                    console.log(res, 'rlsb')
                    messageApi.open({
                        type: 'error',
                        content: `上传失败，请重新上传`
                    });
                }
            }
        });
    };
    const choosePic = (e) => {
        const size = e.file.size / 1024 / 1024;
        if (size > 10) {
            messageApi.open({
                type: 'error',
                content: '上传图片过大，请上传小于10M的图片'
            });
            e.onError();
            setPhoto();
            setPhotoShow(false);
        } else {
            form.validateFields(["photo"]);
            setPhotoShow(false);
            let formData = new FormData();
            formData.append('trackData', e.file);
            uploadAvatar(formData, 'VIP_PHOTO').then((res) => {
                setPhoto(res?.data?.data?.filePath + res?.data?.data?.fileName);
                e.onSuccess(res, e.file);
            });

            // 图片转base64，人脸识别
            setIsHuman(false);
            let reader = new FileReader();
            if (e.file) {
                // 将文件以Data URL形式读入页面
                reader.readAsDataURL(e.file);
                reader.onload = function(e) {
                    const img = new Image();
                    img.src = reader.result;
                    img.onload = () => {
                        const w = img.width;
                        const h = img.height;
                        let finalH = h;
                        let finalW = w;
                        do {
                            finalH = finalH*0.8;
                            finalW = finalW*0.8;
                        } while (finalW > 2000 || finalH > 2000);
                        const canvas = document.createElement("canvas");
                        canvas.width = finalW;
                        canvas.height = finalH;
                        const ctx = canvas.getContext("2d");
                        ctx.fillStyle = "#fff";
                        ctx.fillRect(0, 0, canvas.width, canvas.height);
                        ctx.drawImage(img, 0, 0, finalW, finalH);
                        const dataUrl = canvas.toDataURL("image/jpeg", 0.5);
                        // queryBaiduTOken().then((res) => {
                        //     Verify(dataUrl, res?.access_token);
                        // });
                        Verify(dataUrl);
                    };
                };
                // reader.onload = function(e) {
                //     queryBaiduTOken().then((res) => {
                //         Verify(reader.result, res?.access_token);
                //     });
                // };
            }
        }
    };

    const chooseStudentPic = (e) => {
        const size = e.file.size / 1024 / 1024;
        if (size > 10) {
            messageApi.open({
                type: 'error',
                content: '上传图片过大，请上传小于10M的图片'
            });
            e.onError();
            setPhotoShow2(false);
            setStudentCardPhoto();
        } else {
            form.validateFields(["studentCardPhoto"]);
            setPhotoShow2(false);
            let formData = new FormData();
            formData.append('trackData', e.file);
            uploadAvatar(formData, 'VIP_PHOTO_FILE').then((res) => {
                setStudentCardPhoto(res?.data?.data?.filePath + res?.data?.data?.fileName);
                e.onSuccess(res, e.file);
            });
        }
    };

    const handleCancel = () => setPreviewOpen(false);
    const handleStudentCancel = () => setPreviewStudentOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleStudentPreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewStudentImage(file.url || file.preview);
        setPreviewStudentOpen(true);
        setPreviewStudentTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const onFinishFailed = function(e) {
        setSubmit(false);
    };
    const changeSubmit = function() {
        setSubmit(false);
    };
    const chooseEducation = function(e) {
        setEducationId(e);
    };
    const chooseIndustry = function(e) {
        setIndustryId(e);
    };
    const chooseCardType = function(e) {
        let label = '';
        let cardLabel = cardDirectionary.filter((item) => {
            return item?.value === e;
        });
        label = cardLabel?.[0]?.label;
        let filterCardType = regs.filter((item) => {
            return label.includes(item?.type);
        });
        let type = [filterCardType?.[0]?.require, filterCardType?.[0]?.pattern];
        setCardType(type);
        // setCardId(cardLabel?.[0]?.value);
    };
    const onChange = (e) => {
        setIsconfirm(e.target.checked);
    };
    useEffect(() => {
        (async function() {
            const cardType = await getDictionaryByCode('RLY_DOCUMENTTYPE_OPTION', 'RLY01');
            let selectOpt = [];
            if (cardType?.data && cardType.data.forEach) {
                cardType.data.forEach((item) => {
                    if (item?.text?.includes('行驶')) {
                        return;
                    }
                    selectOpt.push({ value: item?.id, label: item?.text });
                });
                setCardDirectionary(selectOpt);
            }
        })();
        // queryMemberType().then((res) => {
        //     let data = res.data.records.filter((item) => {
        //         return item.typeName === type;
        //     });
        //     setVipMsg(data[0]);
        // })
        queryMemberTypeById({ id }).then((res) => {
            setMemberType(res?.data);
            setVipMsg(res?.data);
        });
        if(cost && cost !== 'judge') {
            setIsconfirm(true);
        }
    }, []);
    useEffect(() => {
        isrequest &&
            getDictionaryByCode('RLY_EDUCATION_TYPE_OPTION', 'RLY03').then((res) => {
                setEducation(res?.data);
                const data = localDS.get('orderParam');
                const arr = res?.data?.filter((item) => {
                    return item.id === (data?.education || tempInfo?.education || infor?.education);
                });
                if (arr?.length === 0) {
                    form.setFieldsValue({ education: '' });
                } else if(arr && arr[0]) {
                    form.setFieldsValue({ education: arr[0].id });
                }
            });
        const orderParam = localDS.get('orderParam');
        const finalData = orderParam || tempInfo || infor;
        if (finalData) {
            setIsschool(finalData?.isSchoolFriend);
            setPhoto(finalData?.photo);
            setStudentCardPhoto(finalData?.studentCardPhoto);
        }
        if(cost && cost !== 'judge') {
            setIsconfirm(true);
        }
    }, [infor, isrequest]);
    // 行业
    useEffect(() => {
        isrequest &&
            getDictionaryByCode('RLY_INDUSTRY_OPTION', 'RLY04').then((res) => {
                setIndustry(res?.data);
                const data = localDS.get('orderParam');
                const arr = res?.data?.filter((item) => {
                    return item.id === (data?.industry || tempInfo?.industry || infor?.industry);
                });
                if (arr?.length === 0) {
                    form.setFieldsValue({ industry: '' });
                } else if(arr && arr[0]) {
                    form.setFieldsValue({ industry: arr[0].id });
                }
            });
    }, [infor, isrequest]);
    useEffect(() => {
        if ((cost  && cost !== 'judge') && orderInfo?.memberId) {
            queryFamilyInfo({ memberId: orderInfo?.memberId, typeId: orderInfo?.memberType }).then(
                (res) => {
                    setTakeMembers(res?.data?.records);
                }
            );
        }
    }, [orderInfo]);
    const dateFormat = 'YYYY/MM/DD';

    const pageView = useRef(null);
    function handleCheckScroll() {
        pageView.current && pageView.current.scrollIntoView({ behavior: 'smooth' });
    }
    const orderParam = localDS.get('orderParam');
    const initData = orderParam || infor;
    // 日期禁用
    const disabledDate = (current) => {
        return current && current > dayjs().endOf('day');
    };
    return (
        isrequest && (
            <>
                {contextHolder}
                <Form
                    className="memberForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    scrollToFirstError={true}
                    validateTrigger="onBlur"
                    initialValues={{
                        membername: tempInfo?.memberName || initData?.memberName || cert?.customerName,
                        birthday:
                            (initData?.birthday && initData?.birthday !== 'false' && dayjs(dealdate(initData?.birthday), dateFormat)) ||
                            (cert?.showDocumentNumber &&
                                cert?.documentType === 'RLY0101' &&
                                dayjs(DocToBirth(cert?.showDocumentNumber), dateFormat)) || '',
                        gender: initData?.gender.toString(),
                        isSchoolFriend:
                            tempInfo?.isSchoolFriend?.toString() ||
                            initData?.isSchoolFriend?.toString(),
                        cardType: initData?.documentType || cert?.documentType,
                        documentNumber: initData?.documentNumber || cert?.showDocumentNumber,
                        phone: initData?.phone || cert?.phoneNumber,
                        education: tempInfo?.education || initData?.education,
                        email: tempInfo?.email || initData?.email,
                        industry: tempInfo?.industry || initData?.industry,
                        photo: tempInfo?.photo || initData?.photo,
                        studentCardPhoto: tempInfo?.studentCardPhoto || initData?.studentCardPhoto
                    }}
                >
                    <Form.Item
                        className="member-item"
                        name="membername"
                        rules={[
                            { required: true, message: '请输入姓名!' },
                            {
                                pattern: form.getFieldValue()?.cardType === 'RLY0101' ? /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/ : /^[a-zA-Z\u4e00-\u9fa5]+$/,
                                message: '请输入正确的姓名！'
                            }
                        ]}
                        label="姓名"
                    >
                        <Input placeholder="请输入姓名" disabled={infor?.state === 1 || (cost && cost !== 'judge')} />
                    </Form.Item>
                    <Form.Item
                        name="birthday"
                        className="member-item"
                        rules={[{ required: true, message: '请选择出生日期！' }]}
                        label="出生日期"
                    >
                        <DatePicker
                            locale={locale}
                            format={dateFormat}
                            placeholder="请选择出生日期"
                            inputReadOnly
                            disabled={infor?.state === 1 || (cost  && cost !== 'judge') || (cert?.showDocumentNumber && cert?.documentType === "RLY0101")}
                            disabledDate={disabledDate}
                            showToday={false}
                        />
                    </Form.Item>
                    <Form.Item
                        className="member-item"
                        name="cardType"
                        rules={[{ required: true, message: '请选择其中一项！' }]}
                        label="证件类型"
                    >
                        <Select
                            disabled={infor?.state === 1 || (cost  && cost !== 'judge') || cert?.documentType}
                            defaultValue="请选择类型"
                            className="edit-select"
                            onChange={chooseCardType}
                            options={cardDirectionary}
                        />
                    </Form.Item>
                    <Form.Item
                        className="member-item"
                        name="documentNumber"
                        rules={cardType}
                        label="证件号码"
                    >
                        <Input
                            placeholder="请输入证件号"
                            disabled={infor?.state === 1 || (cost  && cost !== 'judge') || cert?.showDocumentNumber}
                        />
                    </Form.Item>
                    <Form.Item
                        name="education"
                        label="学历"
                        className="member-item"
                        rules={[{ required: true, message: '请选择您的学历！' }]}
                    >
                        <Select
                            disabled={(cost  && cost !== 'judge')}
                            placeholder="请选择您的学历"
                            onChange={chooseEducation}
                        >
                            {education?.map((item) => {
                                return (
                                    <Option value={item?.id} key={item?.id}>
                                        {item?.text}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    {!props.isPhysical && (
                        <Form.Item
                            name="isSchoolFriend"
                            label="是否为清华校友"
                            className="member-item"
                            rules={[{ required: true, message: '请选择是否为清华校友！' }]}
                        >
                            <Radio.Group
                                disabled={(cost && cost !== 'judge')}
                                onChange={(e) => {
                                    setIsschool(e.target.value);
                                }}
                            >
                                <Radio value="0">否</Radio>
                                <Radio value="1">是</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}

                    <Form.Item
                        name="gender"
                        label="性别"
                        className="member-item"
                        rules={[{ required: true, message: '请选择您的性别！' }]}
                    >
                        <Radio.Group disabled={infor?.state === 1 || (cost && cost !== 'judge')}>
                            <Radio value="1">男</Radio>
                            <Radio value="0">女</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        className="member-item"
                        name="email"
                        rules={[
                            {
                                pattern: /[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/,
                                message: '请输入正确的邮箱地址！'
                            }
                        ]}
                        label="电子邮箱"
                    >
                        <Input placeholder="请输入电子邮箱地址" disabled={(cost && cost !== 'judge')} />
                    </Form.Item>
                    <Form.Item
                        className="member-item"
                        name="phone"
                        rules={[
                            { required: true, message: '请输入手机号码!' },
                            {
                                pattern: /^1[3456789]\d{9}$/,
                                message: '请输入正确的手机号！'
                            }
                        ]}
                        label="联系方式"
                    >
                        <Input placeholder="请输入手机号码" disabled={infor?.state === 1 || (cost && cost !== 'judge')} />
                    </Form.Item>
                    {/* <Form.Item
                        className="member-item"
                        name="industry"
                        rules={[{ required: true, message: '请输入您所在的行业!' }]}
                        label="所在行业"
                    >
                        <Input placeholder="所在行业" disabled={cost} />
                    </Form.Item> */}
                    <Form.Item
                        name="industry"
                        label="所在行业"
                        className="member-item"
                        rules={[{ required: true, message: '请选择您所在的行业！' }]}
                    >
                        <Select
                            disabled={(cost && cost !== 'judge')}
                            placeholder="请选择所在的行业"
                            onChange={chooseIndustry}
                        >
                            {industry?.map((item) => {
                                return (
                                    <Option value={item?.id} key={item?.id}>
                                        {item?.text}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        className="member-item"
                        name="photo"
                        rules={[{ required: true, message: '请上传一张照片' }]}
                        label="照片"
                    >
                        <div className="photoGroup">
                            {/* {photoShow && (orderParam?.photo || tempInfo?.photo || infor?.photo) && (
                                <Image
                                    src={`${window.FILE_IMG_URL}${orderParam?.photo || tempInfo?.photo || infor?.photo}`}
                                />
                            )} */}
                            <Upload
                                disabled={cost && (cost !== 'judge')}
                                action={`${window.FILE_IMG_URL}/file/normalUploadByCode/VIP_PHOTO/vip/800af9b25ca04edcae85ea8616c6ddac`}
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader uploadBox"
                                showUploadList={true}
                                onPreview={handlePreview}
                                // beforeUpload={choosePic}
                                customRequest={choosePic}
                                maxCount={1}
                                capture="user"
                                accept="image/*"
                                defaultFileList={
                                    (orderParam?.photo || tempInfo?.photo || infor?.photo) && [
                                        {
                                            status: 'done',
                                            url: `${window.FILE_IMG_URL}${orderParam?.photo || tempInfo?.photo || infor?.photo}`,
                                        },
                                    ]
                                }
                                onRemove={() => {
                                    setPhoto();
                                    form.setFieldValue("photo", '')
                                }}
                            >
                                <span className="upload-icon">+</span>
                            </Upload>

                        </div>
                    </Form.Item>
                    <Modal
                        open={previewOpen}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                    >
                        <img
                            alt="example"
                            style={{
                                width: '100%'
                            }}
                            src={previewImage}
                        />
                    </Modal>
                    {/* {(isschool === '1' || infor?.isSchoolFriend.toString() === '1') && <><Form.Item */}
                    {Number(isschool) === 1 && !isQ && isQHR && (isQHR !== 'true') && (
                        <>
                            <Form.Item
                                className="member-item"
                                name="studentCardPhoto"
                                rules={[{ message: '请选择一张您的相关校友证件照片!' }]}
                                label="相关校友证件照片"
                            >
                                <div className="photoGroup">
                                    {/* {photoShow2 &&
                                        (orderParam?.studentCardPhoto || tempInfo?.studentCardPhoto || infor?.studentCardPhoto) && (
                                            <Image
                                                src={`${
                                                    window.FILE_IMG_URL
                                                }${orderParam?.studentCardPhoto || tempInfo?.studentCardPhoto ||
                                                    infor?.studentCardPhoto}`}
                                            />
                                        )} */}
                                    <Upload
                                        disabled={cost && (cost !== 'judge')}
                                        action={`${window.FILE_IMG_URL}/file/normalUploadByCode/VIP_PHOTO_FILE/vip/800af9b25ca04edcae85ea8616c6ddac`}
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader uploadBox"
                                        showUploadList={true}
                                        onPreview={handleStudentPreview}
                                        // beforeUpload={chooseStudentPic}
                                        customRequest={chooseStudentPic}
                                        maxCount={1}
                                        defaultFileList={
                                            (orderParam?.studentCardPhoto || tempInfo?.studentCardPhoto ||
                                                infor?.studentCardPhoto) && [
                                                {
                                                    status: 'done',
                                                    url: `${window.FILE_IMG_URL}${orderParam?.studentCardPhoto || tempInfo?.studentCardPhoto ||
                                                        infor?.studentCardPhoto}`,
                                                },
                                            ]
                                        }
                                        onRemove={() => {
                                            form.setFieldValue("studentCardPhoto", '');
                                            setStudentCardPhoto();
                                        }}
                                    >
                                        <span className="upload-icon">+</span>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Modal
                                open={previewStudentOpen}
                                title={previewStudentTitle}
                                footer={null}
                                onCancel={handleStudentCancel}
                            >
                                <img
                                    alt="example"
                                    style={{
                                        width: '100%'
                                    }}
                                    src={previewStudentImage}
                                />
                            </Modal>
                        </>
                    )}
                    {cost && (cost !== 'judge') && (
                        <div className="memberbox">
                            <p className="membertitle">{`${
                                orderInfo?.memberType === '3' ? '家庭' : '携带'
                            }成员信息`}</p>
                            <ul className="memberlist">
                                {takeMembers?.map((item, index) => {
                                    // cardDirectionary
                                    const arr = cardDirectionary.filter((i) => {
                                        return i.value === item?.documentType;
                                    });
                                    return (
                                        <li key={index}>
                                            <div className="photo">
                                                <img
                                                    src={window.FILE_IMG_URL + item?.photo}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="infor">
                                                <p className="name">
                                                    {item?.name}
                                                    <span>{`${
                                                        item?.ageType ? ' (已成年)' : ' (未成年)'
                                                    }`}</span>
                                                </p>
                                                <p className="zj">{`${arr[0].label}：${item?.documentNumber}`}</p>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    <div className="confirm-check"  id="pageView" ref={pageView}>
                        <Checkbox onChange={onChange} checked={isConfirm} disabled={cost && cost !== 'judge'}/> 我已阅读
                        <span
                            className="hytk"
                            onClick={() => {
                                setTkopen(true);
                            }}
                        >
                            《会员章程》
                        </span>
                        并同意
                    </div>
                    <div className="tips">
                        <h3>提示：</h3>
                        <p>1、请认真阅读《会员章程》，勾选确认后再申请办理会员卡。</p>
                        <p>
                        2、请您如实填写申请表中的各项内容(带*为必填项)，以便我们向您提供相关的会员服务。提交成功后，仅限部分内容可进行修改。
                        </p>
                        <p>3、本馆将妥善保护您所填写的个人信息。</p>
                    </div>
                    <div className="empty"> </div>
                    <div className="form-submit">
                        {!submit && (!cost || (cost === 'judge')) && (
                            <button className="confirm" htmltype="submit">
                                提交
                            </button>
                        )}
                        {(submit || cost) && (cost !== 'judge') && (
                            <Pay
                                data={vipMsg}
                                changeSubmit={changeSubmit}
                                memberType={memberType}
                                memberMsg={infor}
                                cost={cost}
                                isschool={form.getFieldValue()?.isSchoolFriend}
                                orderInfo={props?.orderInfo}
                                isConfirm={isConfirm}
                                handleCheckScroll={handleCheckScroll}
                            />
                        )}
                    </div>
                    <Modal
                        title="提示"
                        open={open}
                        onOk={() => {
                            setOpen(false);
                            // handleSubmit();
                        }}
                        onCancel={() => {
                            setOpen(false);
                        }}
                        okText="确认"
                        // cancelText="关闭"
                        cancelButtonProps={{ style: { display: 'none' } }}
                    >
                        <p>
                        您未通过“清华人小程序”的校友身份验证，请上传校友身份证件照片，继续填写申请信息。
                        </p>
                    </Modal>
                    <Modal
                        title="清华大学艺术博物馆会员章程"
                        open={tkopen}
                        className="hytkmodel"
                        onOk={() => {
                            setIsconfirm(true);
                            setTkopen(false);
                        }}
                        onCancel={() => {
                            setTkopen(false);
                        }}
                        okText="确认"
                        cancelButtonProps={{ style: { display: 'none' } }}
                    >
                        <div className="cont" dangerouslySetInnerHTML={{ __html: charter }} />
                    </Modal>
                    <Modal
                        title="提示"
                        open={tipOpen}
                        onOk={() => {
                            setTipOpen(false);
                            history.push('/applyrecords');
                        }}
                        onCancel={() => {
                            setTipOpen(false);
                        }}
                        okText="申请记录"
                        cancelText="关闭"
                    >
                        <p>
                            您有待支付的会员申请订单，请勿重复提交会员申请。请您点击“申请记录”按钮查询待支付的会员申请订单，点击“关闭”将返回到信息填写界面。
                        </p>
                    </Modal>
                </Form>
            </>
        )
    );
};

export default AddMemberForm;
