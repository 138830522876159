import {http, gateWay, saashttp, noTokenHttp, baiduHttp} from './http';

const api = window.API;
const systemId = window.systemId;

// 获取章程
// export async function queryMemberRules() {
//     const res = await http.get(`/${gateWay.vip}/miniApi/queryMemberRules`);
//     return res && {
//         code: res.data.code,
//         data: res.data.data,
//         msg: res.data.msg
//     };
// } // 会员条款前的接口
export async function queryMemberRules(actType) {
    // POI0701：会员动态；POI0702：会员章程；POI0703：会员条款
    const res = await http.post(`/${gateWay.vip}/miniApi/queryMemberRules`, { actType });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    };
}

// 获取会员动态
export async function queryMemberActPageList({ pageSize = 10, pageNum = 1 }) {
    const res = await http.post(`/${gateWay.vip}/memberAct/queryPageList`,{
            entity: {
                pubState:1,
                actType: 'POI0701'
            },
            param: {
                pageSize,
                pageNum
            }
        });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    };
}

// 会员动态详情
export async function queryMemberAct(id) {
    const res = await http.get(`/${gateWay.vip}/memberAct/getById/${id}`);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    };
}

// 添加家人分享,根据会员id查询会员信息
export async function queryMemberById(id) {
    const res = await http.get(`/${gateWay.vip}/miniApi/queryMemberById?id=${id}`);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    };
}

// 查分享卡详情
export async function queryGiftCard(id) {
    const res = await http.get(`/${gateWay.vip}/giftCard/queryById/${id}`);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    };
}

// 添加会员信息
export async function addMember(data){
    // 需要添加办理人信息，才有权限提交，即下面的handleBy和handleByPhone属性
    const res = await http.post(`/${gateWay.vip}/memberBasicInfo/addMember`, {
        ...data,
        handledBy: "1",
        handledByPhone: "1",
    })
    // , {
    //     headers: {
    //         Authorizationu: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzeXN0ZW1JZCI6IjgwMGFmOWIyNWNhMDRlZGNhZTg1ZWE4NjE2YzZkZGFjIiwiZGlzcGxheU5hbWUiOiJxaGJ3Z2FkbWluIiwidXNlck5hbWUiOiJxaGJ3Z2FkbWluIiwiZXhwIjoxNjczNDk5NDgwLCJ1c2VySWQiOiJmMmYxZjMwODkwZWM1MmFiYTUxYTdlMmE0MzQ3YmM1MCJ9.rl08gDFcmW3azhBVtBe-AdDYrcfGafnppILt3qDunYI"
    //     },
    // });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 添加会员信息
export async function preAddMember(data){
    // 需要添加办理人信息，才有权限提交，即下面的handleBy和handleByPhone属性
    const res = await http.post(`/${gateWay.vip}/miniApi/addMember`, {
        ...data,
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 续费添加会员信息
export async function renewMember(data){
    // 需要添加办理人信息，才有权限提交，即下面的handleBy和handleByPhone属性
    const res = await http.post(`/${gateWay.vip}/miniApi/renewMember`, {
        ...data,
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 添加会员信息
export async function cancelOrder(id, payState){
    // 取消订单
    const res = await http.post(`/${gateWay.vip}/miniApi/cancelOrder`, {
        id,
        payState
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}
// 判断能否开发票
export async function checkCanInvoicing(id){
    // 取消订单
    const res = await http.post(`/${gateWay.vip}/miniApi/checkCanInvoicing?id=${id}`);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}
// 申请开发票
export async function applyInvoicing(param){
    // 取消订单
    const res = await http.post(`/${gateWay.vip}/miniApi/applyInvoicing`, param);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}
// 查询实名认证信息
export async function queryCertList() {
    const res = await saashttp.post(`customerCert/queryCertList`);
    return {
        code: res?.data.code,
        data: res?.data.data,
        msg: res?.data.msg
    };
}

// 新增发票抬头
export async function addTitle(param) {
    const res = await saashttp.post(`invoiceApi/addTitle`, param);
    return {
        code: res?.data.code,
        data: res?.data.data,
        msg: res?.data.msg
    };
}
// 修改发票抬头
export async function updateTitle(param) {
    const res = await saashttp.post(`invoiceApi/updateTitle`, param);
    return {
        code: res?.data.code,
        data: res?.data.data,
        msg: res?.data.msg
    };
}
// 查询个人抬头列表
export async function queryTitleList() {
    const res = await saashttp.post(`invoiceApi/queryTitleList`, {});
    return {
        code: res?.data.code,
        data: res?.data.data,
        msg: res?.data.msg
    };
}
// 查询抬头详情
export async function queryTitleById(id) {
    const res = await saashttp.get(`/invoiceApi/queryTitleById/${id}`);
    return {
        code: res?.data.code,
        data: res?.data.data,
        msg: res?.data.msg
    };
}
// 根据商户订单号查询发票详情
export async function queryInvoiceInfo(id) {
    const res = await saashttp.get(`/swInvoiceInfo/queryByOrderNo/${id}`);
    return {
        code: res?.data.code,
        data: res?.data.data,
        msg: res?.data.msg
    };
}
// 获取发票下载地址
export async function downloadInvoice(no) {
    const res = await saashttp.post(`qh/downloadInvoice`, {
        "invoiceOutTradeNo": no
    });
    return {
        code: res?.data.code,
        data: res?.data.data,
        msg: res?.data.msg
    };
}
// 查询会员信息
export async function memberBasicInfo(documentNumber) {
    const res = await http.post(`/${gateWay.vip}/miniApi/queryMemberInfo?documentNumber=${documentNumber}`);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg,
        fullres: res
    };
}

// 批量添加分享卡
export async function addGiftCardBatch({
    arr,
    cost,
    documentNumber,
}){
    const res = await http.post(`/${gateWay.vip}/miniApi/addGiftCardBatch?cost=${cost}&documentNumber=${documentNumber}`, arr);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 登录
export async function ssoLogin() {
    const res = await http.get(`/${gateWay.vip}/qh/ssoLogin?ticket=pm8EKA0Hpw2n01A26R2CPB9B5973VPDCDLVD`);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    };
}

// 家庭成员列表
export async function getFamilyInfo(documentNumber) {
    const res = await http.get(`/${gateWay.vip}/miniApi/getFamilyInfo?documentNumber=${documentNumber}`);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    };
}

// 查询会员类型列表
export async function queryMemberType() {
    const res = await http.post(`/${gateWay.vip}/memberType/queryPageList`, {
        entity: {},
        param: {
            pageSize: 0,
        }
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    };
}

// 通过会员id查询会员信息
export async function queryMemberInfoById({id = ''}){
    const res = await http.get(`/${gateWay.vip}/memberBasicInfo/getById/${id}`);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 查询会员类型详情
export async function queryMemberTypeById({ id = 0 }) {
    const res = await http.get(`/${gateWay.vip}/memberType/getById/${id}`);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    };
}

// 查询会员缴费记录
export async function queryMemberPayRecords({ pageSize = 10, pageNum = 1 }){
    const res = await http.post(`/${gateWay.vip}/memberOperationRecord/queryPageList`, {
        entity: {},
        param: {
            pageSize,
            pageNum
        }
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 绑定实体卡
export async function bindCard(data){
    const res = await http.post(`/${gateWay.vip}/memberBasicInfo/bindCard`, {
        ...data
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 修改会员信息
export async function updateInfor(data){
    const res = await http.post(`/${gateWay.vip}/memberBasicInfo/updateById`, {
        ...data
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 查询家人信息
export async function queryFamilyInfo(data){
    const res = await http.post(`/${gateWay.vip}/memberFamilyInfo/queryPageList`, {
        ...data
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 根据家人id查询家人信息
export async function queryFamilyInfoById(data){
    const res = await http.get(`/${gateWay.vip}/memberFamilyInfo/getById/${data?.id}`);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 查订单
export async function getOrderById(id){
    const res = await http.get(`/${gateWay.vip}/memberOperationRecord/getById/${id}`);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 获取短信验证码
export async function querySmsVCode(phone){
    const res = await saashttp.get(`/saas/querySmsVCode?phone=${phone}`);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 获取是否清华人
export async function queryIsQingHuaPersonByCustomerId(customerId) {
    const res = await saashttp.post(`/qh/queryIsQingHuaPersonByCustomerId`, {
        customerId
    });
    return {
        code: res?.data.code,
        data: res?.data.data,
        msg: res?.data.msg
    };
}

// 查询我的足迹
export async function queryMyFootPrint({memberId, pageNum, pageSize}) {
    const res = await http.post(`/${gateWay.vip}/miniApi/queryMyFootPrint?id=${memberId}`, {
        pageNum,
        pageSize
    });
    return {
        code: res?.data.code,
        data: res?.data.data,
        msg: res?.data.msg
    };
}

// 添加家人信息
export async function addFamily(data){
    const res = await http.post(`/${gateWay.vip}/memberFamilyInfo/save`, data);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 修改家人信息
export async function updateFamily(data){
    const res = await http.post(`/${gateWay.vip}/memberFamilyInfo/updateById`, {
        ...data
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 删除家人信息
export async function delFamily(data){
    const res = await http.post(`/${gateWay.vip}/memberFamilyInfo/deleteBatch`, {
        ...data
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 支付
export async function createOrder(data){
    const res = await http.post(`/${gateWay.vip}/miniApi/createOrder`, data);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 续费支付
export async function createRenewOrder(data){
    const res = await http.post(`/${gateWay.vip}/miniApi/createRenewOrder`, data);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 激活会员
export async function activeMember(data){
    const res = await http.post(`/${gateWay.vip}/memberBasicInfo/activeMember`, {
        ...data
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}
// 激活分享卡
export async function activeCardMember(data){
    const res = await http.post(`/${gateWay.vip}/miniApi/activeMember`, {
        ...data
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}
// 绑定实体卡号
export async function bindCards(param){
    const res = await http.post(`/${gateWay.vip}/miniApi/bindCard`, param);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}
// 校验是否已经绑定过实体卡
export async function checkHasBind(param){
    const res = await http.post(`/${gateWay.vip}/miniApi/checkHasBind`, param);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}
// 分享卡列表
// export async function queryMemberGiftCardds({ pageSize = 10, pageNum = 1 }){
//     const res = await http.post(`/${gateWay.vip}/memberBasicInfo/queryPageList`, {
//         entity: {},
//         param: {
//             pageSize,
//             pageNum,
//             type: 2
//         }
//     });
//     return res && {
//         code: res.data.code,
//         data: res.data.data,
//         msg: res.data.msg
//     }
// }
export async function queryMemberGiftCardds({ pageSize = 10, pageNum = 1 }){
    const res = await http.post(`/${gateWay.vip}/miniApi/queryMyGiftCardPageList`, {
        "pageNum": 0,
        "pageSize": 0
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}

// 会员礼品信息查询
export async function queryMemberGifts({ pageSize = 10, pageNum = 1 }){
    const res = await http.post(`/${gateWay.vip}/memberGift/queryPageList`, {
        entity: {},
        param: {
            pageSize,
            pageNum
        }
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}
// 会员礼品信息查询
export async function queryGiftList(memberId){
    const res = await http.post(`/${gateWay.vip}/miniApi/queryGiftList`, {
        "entity": {
        "memberId": memberId // 会员id
        },
        "param": {}
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}
// 领取礼品
export async function receiveGiftCard(giftId, memberId){
    const res = await http.post(`/${gateWay.vip}/miniApi/receiveGift`, {
        "giftId": giftId, // 礼品id
        "memberId": memberId // 会员id
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}
// 领取分享卡
export async function receiveGiftCards({receivePhone, cardId, smsVCode}){
    const res = await noTokenHttp.post(`/${gateWay.vip}/miniApi/receiveGiftCard`, {
        receivePhone: receivePhone,
        cardId: cardId,
        smsVCode: smsVCode,
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    }
}
// 上传图片
 export function uploadAvatar(formData, code) {
    const fileApi = `${api}sw-file-cloud`;
    const avatarUrl = fileApi + `/file/normalUploadByCode/${code}/vip/${systemId}`;
    return http.post(avatarUrl, formData);
}

// 字典查询
export async function getDictionaryByCode(type, no) {
    const res = await http.get(`/${gateWay.vip}/common/getDictionaryByCode?dictionaryCode=${type}&prefix=${no}`);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    };
}

// 是否能续费
export async function checkCanRenew(id) {
    const res = await http.get(`/${gateWay.vip}/memberBasicInfo/checkCanRenew?id=${id}`);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    };
}

// 有无待支付订单
export async function checkHasUnpaidOrder(id) {
    const res = await http.get(`/${gateWay.vip}/memberBasicInfo/checkHasUnpaidOrder?id=${id}`);
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    };
}

// 有无待支付订单
export async function queryMemberOperationRecord(id, pendingOrders) {
    const res = await http.post(`/${gateWay.vip}/memberOperationRecord/queryMemberOperationRecord`, {
        "entity": {
            "memberId": id,
            // "showState": showState
        },
        "param": {
            "pageSize":0,
            "str": pendingOrders
        }
    });
    return res && {
        code: res.data.code,
        data: res.data.data,
        msg: res.data.msg
    };
}



// 人脸识别 后台处理
// export async function baiduFaceDetect(data) {
//     const url = `http://192.168.10.96:19000/sw-vip-cloud/miniApi/baiduFaceDetect`
//     const res = await baiduHttp.post(url, data);
//     return res?.data
// }
// 人脸识别 后台处理
export async function baiduFaceDetect(data) {
    const res = await http.post(`/${gateWay.vip}/miniApi/baiduFaceDetect`, data);
    return res?.data
}
