import React, { useState, useEffect, useRef } from 'react';
import { localDS } from '../../storage';
import { uploadAvatar, getDictionaryByCode, addFamily, queryFamilyInfoById, updateFamily, baiduFaceDetect } from '../../service';
import { Select, Input, Upload, Form, message, Modal, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { DocToBirth, isIDNum, DealBirthday, dealdate } from '../../utils/validator';
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import './style.scss';

// 不同证件号码的正则表达式
// 这里主要根据type值与接口的字段进行匹配，决定使用哪种正则表达式进行校验，接口字段变化时，改type的值即可
const regs = [
    {
        type: '身份证',
        require: { required: true, message: '请输入身份证号!' },
        pattern: { pattern: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|30|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/, message: '请输入正确的证件号！' }
    },
    {
        type: '护照',
        require: { required: true, message: '请输入护照号码!' },
        pattern: { pattern: /^([a-zA-z]|[0-9]){5,17}$/, message: '请输入正确的护照号码!' }
    },
    {
        type: '台胞',
        require: { required: true, message: '请输入台湾居民来往大陆通行证号码!' },
        pattern: { pattern: /^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/, message: '请输入正确的台湾居民来往大陆通行证号码!' }
    },
    {
        type: '港澳',
        require: { required: true, message: '请输入港澳居民来往内地通行证!' },
        pattern: { pattern: /^([A-Z]\d{6,10}(\(\w{1}\))?)$/, message: '请输入正确的港澳居民来往内地通行证!'}
    },
    {
        type: '军官',
        require: { required: true, message: '请输入军官证!' },
        pattern: { pattern: /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/, message: '请输入正确的军官证!' }
    }
]

const AddFamily = (props) => {
    const formRef = useRef();
    const [cardType, setCardType] = useState([]);
    const [cardDirectionary, setCardDirectionary] = useState([]);
    const [picUrl, setPicUrl] = useState('');
    // const [cardId, setCardId] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [familyInfo, setFamilyInfo] = useState();
    const [photoShow, setPhotoShow] = useState(true);
    const [isHuman, setIsHuman] = useState(true);
    const [canChooseBirth, setCanChooseBirth] = useState(false);
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const classId = new URLSearchParams(window.location.search).get('id');
    const type = new URLSearchParams(window.location.search).get('type');
    const edit = new URLSearchParams(window.location.search).get('edit');
    const typeId = new URLSearchParams(window.location.search).get('typeId');
    // 将文件信息保存到FileReader中
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    // 控制图片预览窗口关闭
    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    if(edit) {
        document.title = '修改成员信息';
    }
    useEffect(() => {
        // 获取会员家庭成员信息或者携带成员信息
        (async function(){
            if(classId){
                const familyInfo = await queryFamilyInfoById({id: classId});
                setFamilyInfo(familyInfo?.data);
                const obj = {
                    username: familyInfo?.data?.name,
                    // age: familyInfo?.data?.ageType,
                    cardType: familyInfo?.data?.documentType,
                    cardId: familyInfo?.data?.documentNumber,
                    phonenumber: familyInfo?.data?.phone,
                    photo: familyInfo?.data?.photo,
                }
                if(typeId?.toString() !== '4') {
                    obj.birthDate = familyInfo?.data?.birthDate && dayjs(dealdate(familyInfo?.data?.birthDate), 'YYYY/MM/DD');
                }
                formRef.current.setFieldsValue(obj)
                if(familyInfo?.data?.documentType === "RLY0101") {
                    setCanChooseBirth(true)
                } else if(familyInfo?.data?.documentType !== "RLY0101") {
                    setCanChooseBirth(false)
                }
                setPicUrl(familyInfo?.data?.photo)
            }
            const cardType = await getDictionaryByCode("RLY_DOCUMENTTYPE_OPTION", "RLY01");
            let selectOpt = [];
            if(cardType?.data && cardType.data.forEach){
                cardType.data.forEach((item) => {
                   if(item?.text?.includes('行驶')){
                        return;
                    }
                selectOpt.push({value: item?.id, label: item?.text});
            })
            setCardDirectionary(selectOpt);
            }
        })();
    }, []);

    const param = new URLSearchParams(props.location.search);
    const memberId = param.get('memberid') || localDS.get('memberId');

    const cert = localDS.get('cert');
    const onFinish = function(){
        if(!picUrl){
            messageApi.open({
                type: 'error',
                content: '请正确上传图片！',
            });
            return;
        }
        if(!isHuman){
            messageApi.open({
                type: 'error',
                content: '请露正脸拍照，以便入馆识别，请重新拍照！',
            });
            return;
        }
        let data = form.getFieldValue();

        if(data?.cardId === cert?.showDocumentNumber){
            messageApi.open({
                type: 'error',
                content: '请勿使用相同身份证号',
            });
            return;
        }
        let filterData = {
            memberId,
            // ageType: data?.age,
            name: data?.username,
            documentType: data?.cardType,
            documentNumber: data?.cardId,
            phone: data?.phonenumber,
            photo: picUrl,
            // birthDate: data?.birthDate && DealBirthday(data?.birthDate),
        };
        if(typeId?.toString() !== '4') {
            filterData.birthDate = data?.birthDate && DealBirthday(data?.birthDate);
        }
        // console.log(DealBirthday(data?.birthDate))
        if(classId){
            let familyData = {
                id: classId,
                // ageType: filterData?.age,
                name: filterData?.name,
                documentType: filterData?.documentType,
                documentNumber: filterData?.documentNumber,
                phone: filterData?.phone,
                photo: picUrl,
                birthDate: filterData?.birthDate,
            }
            updateFamily(familyData).then((res) => {
                if(res?.code !== 200){
                    messageApi.open({
                        type: 'error',
                        content: res?.msg
                    })
                    return;
                }else{
                    messageApi.info('提交成功！');
                    history.back();
                }
            })
        }else{
            let param = {...filterData}
            if(typeId) {
                param.typeId = typeId
            }
            addFamily(param).then((res) => {
                if(res?.code !== 200){
                    messageApi.open({
                        type: 'error',
                        content: res?.msg,
                    });
                    // alert(JSON.stringify(res))
                    return;
                }else{
                    messageApi.info("提交成功！");
                    setTimeout(() => {
                        history.back();
                    }, 1.5 * 1000);
                }
            })
        }

    }
    const chooseCardType = function(e){
        let label = '';
        let cardLabel = cardDirectionary.filter((item) => {
            return item?.value === e;
        })
        label = cardLabel?.[0]?.label;
        let filterCardType = regs.filter((item) => {
            return label.includes(item?.type);
        })
        let type = [filterCardType?.[0]?.require, filterCardType?.[0]?.pattern];
        setCardType(type);
        // setCardId(cardLabel?.[0]?.value);
    }
    // 人脸识别验证
    const Verify = (val) => {
        let data = {
            'image': val.replace(/^data:image\/\w+;base64,/, ""),
            'image_type': 'BASE64',
            // 'image': val,
            // 'image_type': 'URL',
            'max_face_num': 2,
            'face_field': "quality"
        }
        baiduFaceDetect(data).then(res => {
            if (res?.data?.error_msg === 'SUCCESS') {
                let result = res?.data?.result;
                if (result.face_num > 1) {
                    messageApi.open({
                        type: 'error',
                        content: "多人一起拍照将导致无法刷脸入馆，请重新拍照",
                    });
                } else if (result.face_list[0].quality.blur > 0.5) {
                    messageApi.open({
                        type: 'error',
                        content: "照片太模糊，请重新拍照",
                    });
                } else if (result.face_list[0].quality.illumination < 60) {
                    messageApi.open({
                        type: 'error',
                        content: "脸部太暗，请重新拍照",
                    });
                } else if (result.face_list[0].quality.completeness === 0) {
                    messageApi.open({
                        type: 'error',
                        content: "脸部拍摄不完整，请重新拍照",
                    });
                } else if (result.face_list[0].quality.occlusion.left_eye > 0.6 || result.face_list[0].quality.occlusion.right_eye > 0.6) {
                    messageApi.open({
                        type: 'error',
                        content: "眼睛被遮挡，请重新拍照",
                    });
                } else if (result.face_list[0].quality.occlusion.nose > 0.7) {
                    messageApi.open({
                        type: 'error',
                        content: "鼻子被遮挡，请重新拍照",
                    });
                } else if (result.face_list[0].quality.occlusion.mouth > 0.7) {
                    messageApi.open({
                        type: 'error',
                        content: "嘴巴被遮挡，请重新拍照",
                    });
                } else if (result.face_list[0].quality.occlusion.chin_contour > 0.6) {
                    messageApi.open({
                        type: 'error',
                        content: "下巴被遮挡，请重新拍照",
                    });
                } else if (result.face_list[0].quality.occlusion.right_cheek > 0.5 || result.face_list[0].quality.occlusion.left_cheek > 0.5) {
                    messageApi.open({
                        type: 'error',
                        content: "脸颊被遮挡，请重新拍照",
                    });
                } else {
                    setIsHuman(true);
                }
              } else {
                if(res.code === 222202) {
                    messageApi.open({
                        type: 'error',
                        content: `请露正脸拍照，以便入馆识别，请重新拍照`
                    });
                } else if(res.code === 222304) {
                    messageApi.open({
                        type: 'error',
                        content: `上传图片过大,请重新上传`
                    });
                } else {
                    console.log(res, 'rlsb')
                    messageApi.open({
                        type: 'error',
                        content: `上传失败，请重新上传`
                    });
                }
              }
        })
    }
    const choosePic = function(e){
        const size = e.file.size / 1024 / 1024;
        if(size > 10) {
            messageApi.open({
                type: 'error',
                content: '上传图片过大，请上传小于10M的图片',
            });
            e.onError()
        } else {
            form.validateFields(["photo"]);
            setPhotoShow(false);
            let formData = new FormData();
            formData.append('trackData', e.file);
            uploadAvatar(formData, 'VIP_PHOTO').then((res) => {
                setPicUrl(res?.data?.data?.filePath + res?.data?.data?.fileName);
                e.onSuccess(res, e.file);
            })

            // 图片转base64，人脸识别
            setIsHuman(false)
            let reader = new FileReader();
            if (e.file) {
                reader.readAsDataURL(e.file);
                reader.onload = function(e) {
                    const img = new Image();
                    img.src = reader.result;
                    img.onload = () => {
                        const w = img.width;
                        const h = img.height;
                        let finalH = h;
                        let finalW = w;
                        do {
                            finalH = finalH*0.8;
                            finalW = finalW*0.8;
                        } while (finalW > 2000 || finalH > 2000);
                        const canvas = document.createElement("canvas");
                        canvas.width = finalW;
                        canvas.height = finalH;
                        const ctx = canvas.getContext("2d");
                        ctx.fillStyle = "#fff";
                        ctx.fillRect(0, 0, canvas.width, canvas.height);
                        ctx.drawImage(img, 0, 0, finalW, finalH);
                        const dataUrl = canvas.toDataURL("image/jpeg", 0.5);
                        // queryBaiduTOken().then((res) => {
                        //     Verify(dataUrl, res?.access_token);
                        // });
                        Verify(dataUrl);
                    };
                };
                // reader.onload = function (e) {
                //     queryBaiduTOken().then(res => {
                //         Verify(reader.result, res?.access_token)
                //     })
                // }
            }
        }


    }

    const dateFormat = 'YYYY/MM/DD'; // 日期格式
    // 身份证提取出生日期
    const onValuesChange = (val) => {
        let data = form.getFieldValue();
        if(data?.cardType === "RLY0101") {
            setCanChooseBirth(true)
            if(data?.cardId && isIDNum(data?.cardId)) {
                const birthDate = dayjs(DocToBirth(data?.cardId), dateFormat);
                form.setFieldsValue({birthDate})
            }
        } else if(data?.cardType !== "RLY0101") {
            setCanChooseBirth(false)
        }
    }

    // 日期禁用
    const disabledDate = (current) => {
        return current && current > dayjs().endOf('day');
    };
    return (
        <>
            {contextHolder}
            <div className="familyEdit addfamilyedit">
                <Form
                onFinish={onFinish}
                form={form}
                validateTrigger="onBlur"
                ref={formRef}
                initialValues={{
                    username: '',
                    cardType: '',
                    cardId: '',
                    birthDate: '',
                    phonenumber: '',
                    photo: '',
                }}
                onValuesChange={onValuesChange}
                >
                    {/* <h3>类型</h3> */}
                    {/* <Form.Item
                     className="edit-item"
                     name="age"
                     rules={[
                        { required: true, message: '请选择其中一项！' }
                     ]}
                     >
                        <Radio.Group>
                            <Radio value="1">成年人</Radio>
                            <Radio value="0">未成年</Radio>
                        </Radio.Group>
                    </Form.Item> */}
                    <h3>姓名</h3>
                    <Form.Item
                        name="username"
                        className="edit-item"
                        rules={[
                            { required: true, message: '请输入姓名!' },
                            { pattern: form.getFieldValue()?.cardType === 'RLY0101' ? /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/ : /^[a-zA-Z\u4e00-\u9fa5]+$/, message: '请输入正确的姓名！' },
                        ]}
                        >
                        <Input placeholder="请输入姓名"/>
                    </Form.Item>
                        <h3>证件类型</h3>
                        <Form.Item
                            className="edit-item"
                            name="cardType"
                            rules={[
                                { required: true, message: '请选择其中一项！' }
                            ]}
                        >
                            <Select
                                defaultValue="请选择类型"
                                className="edit-select"
                                onChange={ chooseCardType }
                                options={cardDirectionary}
                                // disabled={!!classId}
                            />
                        </Form.Item>
                    <h3>证件号码</h3>
                    <Form.Item
                        name="cardId"
                        className="edit-item"
                        rules = {
                            cardType
                        }
                        >
                        <Input placeholder={ cardType?.[0]?.message?.slice(0, cardType?.[0]?.message?.length - 1) } />
                    </Form.Item>
                    {typeId?.toString() !== '4' && <>
                    <h3>出生日期<span>（未满18岁为未成年）</span></h3>
                    <Form.Item
                        name="birthDate"
                        className="edit-item"
                        rules={[{ required: true, message: '请选择出生日期！' }]}
                    >
                        <DatePicker
                            locale={locale}
                            format={dateFormat}
                            placeholder="请选择出生日期"
                            inputReadOnly
                            disabled={canChooseBirth}
                            disabledDate={disabledDate}
                            showToday={false}
                        />
                    </Form.Item>
                    </>}
                    <h3>联系电话</h3>
                    <Form.Item
                        name="phonenumber"
                        className="edit-item"
                        rules={[
                            { required: true, message: '请输入手机号!' },
                            { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号！' },
                        ]}
                        >
                        <Input placeholder="请输入手机号"/>
                    </Form.Item>
                    <h3>照片<span>（请上传本人照片，否则影响相关会员服务）</span></h3>
                        {/* {photoShow && familyInfo?.photo && (<Img src={`${window.FILE_IMG_URL}${familyInfo?.photo}`} />)} */}
                        <Form.Item
                            className="edit-item"
                            name="photo"
                            rules={[
                                { required: true, message: '请上传一张照片' }
                            ]}
                        >
                        {((classId && familyInfo) || !classId) && <div className="photoGroup">
                            <Upload
                                action={`${window.FILE_IMG_URL}/file/normalUploadByCode/VIP_PHOTO/vip/800af9b25ca04edcae85ea8616c6ddac`}
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader uploadBox"
                                showUploadList={true}
                                onPreview={handlePreview}
                                // beforeUpload={choosePic}
                                customRequest={choosePic}
                                maxCount={1}
                                capture="user"
                                accept="image/*"
                                defaultFileList={
                                    familyInfo?.photo && [
                                        {
                                            status: 'done',
                                            url: `${window.FILE_IMG_URL}${familyInfo?.photo}`,
                                        },
                                    ]
                                }
                                onRemove={() => {
                                    form.setFieldValue("photo", '');
                                    setPicUrl();
                                }}
                            ><span className='upload-icon'>+</span></Upload>
                        </div>}
                        </Form.Item>

                    <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                        <img
                            alt="example"
                            style={{
                                width: '100%',
                            }}
                            src={previewImage}
                        />
                    </Modal>
                    <div className='btn-group'>
                        <button className="cancel" onClick={(e) => {
                            e.preventDefault();
                            history.back();
                        }}>取消</button>
                        <button className="confirm" htmltype="submit">确定</button>
                    </div>
                </Form>
                <div className="empty" />
            </div>
        </>

    )
}

export default AddFamily;
